<template>
	<div class="row">
		<div class="col">
			<div class="form-group mb-0">
				<form class="w-100">
					<div class="form-group">
						<label :for="`${fieldIdPrefix}-cc-name`">{{ translate('name_on_card') }} <span class="text-danger">*</span></label>
						<span
							:id="`${fieldIdPrefix}-cc-name`"
							:class="creditCardHasErrors('name') ? 'is-invalid' : ''"
							class="form-field form-control" />
						<template v-if="creditCardHasErrors('name')">
							<p
								v-for="error in creditCardErrors.name.errorMessages"
								:key="error"
								class="custom-invalid-feedback animated fadeIn text-danger mb-0"
								v-text="error" />
						</template>
					</div>
					<div class="form-group">
						<label :for="`${fieldIdPrefix}-cc-number`">{{ translate('card_number') }} <span class="text-danger">*</span></label>
						<span
							:id="`${fieldIdPrefix}-cc-number`"
							:class="creditCardHasErrors('number') ? 'is-invalid' : ''"
							class="form-field form-control" />
						<template v-if="creditCardHasErrors('number')">
							<p
								v-for="error in creditCardErrors.number.errorMessages"
								:key="error"
								class="custom-invalid-feedback animated fadeIn text-danger mb-0"
								v-text="error" />
						</template>
					</div>
					<div class="row">
						<div class="form-group col-12 col-md-4">
							<label :for="`${fieldIdPrefix}-cc-expiration-month`">{{ translate('expiry_month') }} <span class="text-danger">*</span></label>
							<span
								:id="`${fieldIdPrefix}-cc-expiration-month`"
								:class="creditCardHasErrors('expiration_month') ? 'is-invalid' : ''"
								class="form-field form-control" />
							<template v-if="creditCardHasErrors('expiration_month')">
								<p
									v-for="error in creditCardErrors.expiration_month.errorMessages"
									:key="error"
									class="custom-invalid-feedback animated fadeIn text-danger mb-0"
									v-text="error" />
							</template>
						</div>
						<div class="form-group col-12 col-md-4">
							<label :for="`${fieldIdPrefix}-cc-expiration-year`">{{ translate('expiry_year') }} <span class="text-danger">*</span></label>
							<span
								:id="`${fieldIdPrefix}-cc-expiration-year`"
								:class="creditCardHasErrors('expiration_year') ? 'is-invalid' : ''"
								class="form-field form-control" />
							<template v-if="creditCardHasErrors('expiration_year')">
								<p
									v-for="error in creditCardErrors.expiration_year.errorMessages"
									:key="error"
									class="custom-invalid-feedback animated fadeIn text-danger mb-0"
									v-text="error" />
							</template>
						</div>
						<div class="form-group col-12 col-md-4">
							<label :for="`${fieldIdPrefix}-cc-cvc`">{{ translate('cvv') }} <span class="text-danger">*</span></label>
							<span
								:id="`${fieldIdPrefix}-cc-cvc`"
								:class="creditCardHasErrors('security_code') ? 'is-invalid' : ''"
								class="form-field form-control" />
							<template v-if="creditCardHasErrors('security_code')">
								<p
									v-for="error in creditCardErrors.security_code.errorMessages"
									:key="error"
									class="custom-invalid-feedback animated fadeIn text-danger mb-0"
									v-text="error" />
							</template>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import { VGS_V2_SCRIPT_SOURCE } from '@/settings/CreditCard';
import { Profile } from '@/translations';
import Country from '@/util/Country';

export default {
	name: 'LacoreCreditCardFormV2',
	messages: [Profile],
	props: {
		fieldIdPrefix: {
			type: String,
			required: true,
		},
		addressLine1: {
			type: String,
			required: true,
		},
		addressLine2: {
			type: String,
			required: true,
		},
		city: {
			type: String,
			required: true,
		},
		postcode: {
			type: String,
			required: true,
		},
		newStateCode: {
			type: String,
			required: false,
			default: '',
		},
		regionId: {
			type: [String, Number],
			required: true,
		},
		countryCode: {
			type: String,
			required: true,
		},
		addressForm: {
			type: Object,
			default: () => ({}),
		},
		importCreditCardErrors: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			countryIso: new Country(),
			state: new Country(),
			paymentForm: {},
			creditCardErrors: {},
			lacoreV2ApplicationId: process.env.VUE_APP_LACORE_V2_APPLICATION_ID,
			lacoreV2Environment: process.env.VUE_APP_LACORE_V2_ENVIRONMENT,
			lacoreV2VaultId: process.env.VUE_APP_LACORE_V2_VAULT_ID,
		};
	},
	watch: {
		importCreditCardErrors(newVal) {
			this.creditCardErrors = this.mergeErrors(this.creditCardErrors, newVal);
		},
	},
	mounted() {
		this.formSetupV2();

		this.preparing = true;
		setTimeout(() => {
			this.$emit('setupEnd', this.handleResponse);
		}, 3000);
	},
	methods: {
		creditCardHasErrors(field) {
			return typeof this.creditCardErrors[field] !== 'undefined' && this.creditCardErrors[field].errorMessages.length > 0;
		},
		formSetupV2() {
			this.$emit('setupStart');
			const VGSScript = document.createElement('script');
			VGSScript.setAttribute('src', VGS_V2_SCRIPT_SOURCE);
			document.head.appendChild(VGSScript);
			VGSScript.addEventListener('load', () => {
				// VGS Collect form initialization
				this.paymentForm = VGSCollect.create(this.lacoreV2VaultId, this.lacoreV2Environment, () => {}); // eslint-disable-line no-undef
				this.paymentForm.useCname(process.env.VUE_APP_LACORE_V2_API);
				this.paymentForm.field(`#${this.fieldIdPrefix}-cc-name`, {
					type: 'text',
					name: 'name',
					placeholder: `${this.translate('name_on_card')}`,
					validations: ['required'],
				});
				this.paymentForm.field(`#${this.fieldIdPrefix}-cc-number`, {
					type: 'card-number',
					name: 'number',
					placeholder: `${this.translate('card_number')}`,
					validations: ['required', 'validCardNumber'],
				});
				this.paymentForm.field(`#${this.fieldIdPrefix}-cc-cvc`, {
					type: 'card-security-code',
					name: 'security_code',
					placeholder: `${this.translate('cvv')}`,
					validations: ['required', 'validCardSecurityCode'],
				});
				this.paymentForm.field(`#${this.fieldIdPrefix}-cc-expiration-month`, {
					type: 'number',
					name: 'expiration_month',
					placeholder: `${this.translate('expiry_month')}`,
					validations: ['required'],
				});
				this.paymentForm.field(`#${this.fieldIdPrefix}-cc-expiration-year`, {
					type: 'number',
					name: 'expiration_year',
					placeholder: `${this.translate('expiry_year')} (YYYY)`,
					validations: ['required'],
				});
			});
		},
		resetErrors() {
			this.creditCardErrors = {
				name: {
					errorMessages: [],
				},
				number: {
					errorMessages: [],
				},
				expiration_month: {
					errorMessages: [],
				},
				expiration_year: {
					errorMessages: [],
				},
				security_code: {
					errorMessages: [],
				},
			};
		},
		async submitForm() {
			let stateCode = '';
			let countryCode = '';
			try {
				if (this.newStateCode !== '' && typeof this.newStateCode !== 'undefined') {
					stateCode = this.newStateCode;
				} else {
					const stateResponse = await this.state.getState(this.regionId);
					stateCode = stateResponse.attributes.code;
				}

				// const countryResponse = await this.countryIso.getCountry(this.countryCode); // iso_code_3 no longer used
				countryCode = this.countryCode;
			} catch (error) {
				stateCode = '';
			}
			return new Promise((resolve, reject) => {
				this.paymentForm.submit(`/applications/${ this.lacoreV2ApplicationId }/tokens`, {
					data: {
						type: 'PAYMENT_CARD',
						address: {
							line1: this.addressLine1,
							line2: this.addressLine2,
							city: this.city,
							region: stateCode,
							postal_code: this.postcode,
							country: countryCode,
						},
					},
				}, (status, data) => {
					resolve(data);
				}, (error) => {
					reject(error);
				});
			});
		},
		async handleResponse() {
			this.resetErrors();
			try {
				const smtForm = await this.submitForm();
				if (typeof smtForm.total !== 'undefined') {
					smtForm._embedded.errors.forEach((error) => { // eslint-disable-line no-underscore-dangle
						error.forEach((errorItem) => {
							this.creditCardErrors[errorItem.field || 'number'].errorMessages.push(errorItem);
						});
					});
					return null;
				}
				if (typeof smtForm.id !== 'undefined') {
					return smtForm.id;
				}
			} catch (error) {
				this.creditCardErrors = { ...error };
				return null;
			}
			return null;
		},
		mergeErrors(errors1, errors2) {
			const errors1Entries = Object.entries(errors1);
			const errors2Entries = Object.entries(errors2);

			const mergedEntries = errors1Entries.map(([key]) => ([key, {
				errorMessages: [
					...errors1Entries[key]?.errorMessages ?? [],
					...errors2Entries[key]?.errorMessages ?? [],
				],
			}]));

			return Object.fromEntries(mergedEntries);
		},
	},
};
</script>
