<template>
	<div>
		<template v-if="totalsHasData && !loading">
			<div
				class="text-dark alert alert-secondary-darker mb-0">
				<h4
					:class="{ 'mb-1': totalVolume }"
					class="text-center text-uppercase">
					{{ translate('order_summary') }}
				</h4>
				<h5
					v-if="totalVolume && !isAgencySite"
					class="text-center font-weight-normal">
					{{ translate('total_volume') }}: {{ totalVolume }}
				</h5>
				<h6
					v-if="totalWeeks && isDistributor"
					class="text-center font-weight-normal">
					{{ translate('weeks') }}: {{ totalWeeks }}
				</h6>
				<div
					v-if="!!$slots.top"
					class="row">
					<div class="col">
						<slot name="top" />
					</div>
				</div>
				<div
					class="font-weight-normal">
					<template v-for="(specificTotal, key) in totals">
						<div :key="key">
							<div
								class="row pb-2"
								:class="noGutters ? 'no-gutters' : ''">
								<div class="col-7">
									<div
										:class="{
											'text-medium': smallTextTotals.includes(key),
										}"
										class="text-dark font-weight-light">
										{{ key == 'shipping' ? translate('shipping_and_handling') : translate(key) }}
										<discount-detail
											v-if="key === 'discount'"
											:id="`discount-widget`"
											:discount-detail="discountDetail" />
									</div>
								</div>
								<div class="col-5">
									<div
										:class="{
											'text-discount': ['discount', 'points'].includes(key),
											'text-dark font-weight-light': !['discount', 'points'].includes(key),
											'text-medium': smallTextTotals.includes(key),
										}"
										class="text-right">
										{{ specificTotal }}
									</div>
								</div>
							</div>
						</div>
					</template>
					<div class="row mb-3">
						<div class="col">
							<div class="border-top">
								<div class="row pt-2">
									<div class="col-4">
										<div class="h5 mb-0">
											{{ translate('total') }}
										</div>
									</div>
									<div class="col-8">
										<div class="h5 text-right mb-0">
											{{ total }}
										</div>
									</div>
								</div>
								<div
									v-if="!!exchange"
									class="row">
									<div class="col h6 exchange-text text-right">
										{{ exchange }}*
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="!!$slots.default"
					class="row">
					<div class="col">
						<slot />
					</div>
				</div>
				<div
					v-if="!hideAction"
					class="row">
					<div class="col">
						<button
							type="button"
							:class="actionClasses"
							class="btn btn-primary btn-block"
							:disabled="disableAction"
							@click="$emit('action')">
							{{ !!actionText ? actionText : translate('confirm') }}
						</button>
					</div>
				</div>
				<div
					v-if="!!exchange"
					class="row mt-3">
					<div class="col exchange-text text-small">
						{{ translate('exchange_disclaimer') }}
					</div>
				</div>
			</div>
			<div v-if="isAgencySite">
				<!-- Cart badges -->
				<div class="row mt-3 justify-content-center">
					<!-- 30-day money back guarantee -->
					<div class="col-auto">
						<img
							class="img-fluid"
							style="max-height: 100px;"
							:src="thirtyDayMoneyBackGuaranteeImage">
					</div>
					<!-- 2-day delivery guarantee -->
					<div class="col-auto">
						<img
							class="img-fluid"
							style="max-height: 100px;"
							:src="twoDayDeliveryGuaranteedImage">
					</div>
				</div>
			</div>
		</template>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate(noDataLabel)"
			:icon-class="noDataIcon"
			:loading="loading"
			:has-data="cartItemsHasData && totalsHasData" />
	</div>
</template>
<script>
import CartDisplay from '@/mixins/CartDisplay';
import DiscountDetail from '@/components/Cart/DiscountDetail';

export default {
	name: 'CartTotals',
	components: { DiscountDetail },
	mixins: [CartDisplay],
	props: {
		actionText: {
			type: String,
			default: '',
		},
		actionClasses: {
			type: String,
			default: '',
		},
		disableAction: {
			type: Boolean,
			default: false,
		},
		hideAction: {
			type: Boolean,
			default: false,
		},
		noGutters: {
			type: Boolean,
			default: false,
		},
		noDataLabel: {
			type: String,
			default: 'empty_cart',
		},
		noDataIcon: {
			type: String,
			default: 'fa fa-exclamation-circle',
		},
	},
	computed: {
		thirtyDayMoneyBackGuaranteeImage() {
			return require('@/assets/images/themes/Velovita/cart/thirty-day-money-back-badge.png'); // eslint-disable-line global-require
		},
		twoDayDeliveryGuaranteedImage() {
			return require('@/assets/images/themes/Velovita/cart/two-day-delivery-badge.png'); // eslint-disable-line global-require
		},
	},
};
</script>
