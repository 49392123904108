import { CHECKOUT_GUEST_STEPS as flowSteps } from '@/settings/Wizard';
import {
	Addresses,
	Countries,
	Dates,
	Events,
	Grids,
	Months,
	NIN,
	Packages,
	PaymentMethods,
	Products,
	Profile,
	Purchase,
	Users,
	Validations,
	CustomerPromoModal,
	Cart,
} from '@/translations';
import Checkout from '@/util/Purchase';
import Captcha from '@/mixins/Captcha';
import Steps from './Steps';
import store from '../store';

export default {
	messages: [Addresses, Countries, Dates, Events, Grids, Months, NIN, Packages, PaymentMethods, Products, Profile, Purchase, Users, Validations, CustomerPromoModal, Cart],
	mixins: [Captcha, Steps],
	data() {
		return {
			validator: new Checkout(),
			preparing: 0,
		};
	},
	computed: {
		steps() {
			const steps = [];
			Object.keys(flowSteps).forEach((stepName) => {
				if (flowSteps[stepName].innerSteps) {
					steps.push(...flowSteps[stepName].innerSteps);
				}
				steps.push({ [stepName]: flowSteps[stepName] });
			});
			return steps;
		},
		stepNames() {
			return this.steps.map((step) => Object.keys(step)[0]);
		},
		validating() {
			try {
				return this.validator.data.loading;
			} catch (error) {
				return false;
			}
		},
		validationErrors() {
			try {
				return this.validator.errors.errors;
			} catch (error) {
				return [];
			}
		},
		currentModule() {
			const [mod] = this.$route.matched;
			return mod.name;
		},
		currentValidation() {
			return this.getStepValidationByIndex(this.currentStep());
		},
		isLastStep() {
			return this.currentStep() === this.steps.length - 1;
		},
	},
	methods: {
		getSponsorInfo(sponsorId) {
			return this.validator.getSponsorInfo(sponsorId)
				.then((response) => response.response)
				.catch((error) => Promise.reject(error));
		},
		validateStep(payload, convertToFormData = false) {
			if (this.validating) {
				return null;
			}
			return this.getCaptchaToken('checkout').then((token) => {
				payload['g-recaptcha-response'] = token;
				payload.cart_id = this.$user.getCartId();
				const finalPayload = convertToFormData ? this.objectToFormData(payload) : payload;
				return this.validator.validate(finalPayload, this.currentModule)
					.then((response) => response)
					.catch((error) => Promise.reject(error));
			});
		},
		clearValidationError(field) {
			this.validator.errors.clear(field);
		},
		saveStep(data, goToNext = true, emitSaved = true) {
			const { step } = data;
			this.storeStepInfo(data, step).then(() => {
				if (emitSaved) {
					this.$emit('saved');
				}
				if (goToNext) {
					this.nextStep();
				}
			}).catch(() => {});
		},
		storeStepInfo(data, validation) {
			const step = this.getStepByValidation(validation);
			const payload = {};
			payload[Object.keys(step)[0]] = data;
			return store.dispatch('saveInfo', payload);
		},
		getStepInformation(stepName) {
			const currentStep = typeof stepName === 'undefined' ? this.$route.name : stepName;
			return store.getters.getStepInformation(currentStep);
		},
		saveCart(cartId) {
			return store.dispatch('saveCart', cartId);
		},
		removeInfo() {
			return store.dispatch('removeInfo').catch(() => {});
		},
		getStepByIndex(index) {
			return this.steps[index];
		},
		getStepNameByIndex(index) {
			return this.stepNames[index];
		},
		getStepValidationByIndex(index) {
			try {
				return this.getStepByIndex(index)[this.getStepNameByIndex(index)].validation;
			} catch (error) {
				return false;
			}
		},
		getStepValidationByName(stepName) {
			try {
				return this.getStepByName(stepName)[stepName].validation;
			} catch (error) {
				return false;
			}
		},
		getStepByName(stepName) {
			return this.steps.find((step) => Object.keys(step)[0] === stepName);
		},
		getStepByValidation(validation) {
			return this.steps.find((step) => step[Object.keys(step)[0]].validation === validation);
		},
	},
};
